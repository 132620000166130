jQuery(document).ready(function($) {
	/* Mobile Menu */
	mobileNavigationMenuSelector = ".mobile-navigation-menu"
	menu = new Mmenu(mobileNavigationMenuSelector, {
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	}, {
		fixedElements: {
			elemInsertMethod: "appendTo"
		}
	});

	api = menu.API;
	$(".mobile-menu-button").click(function() {
		if ($(mobileNavigationMenuSelector).hasClass("mm-menu_opened")) {
			api.close();
		}
		else {
			api.open();
		}
	});

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-dropdown-toggle").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".c-nav-dropdown-toggle").removeClass("open");
				event.preventDefault();
				$(this).parent(".c-nav-dropdown-toggle").addClass("open");
			}
		});
	}
	else {
		$(".c-nav-dropdown-toggle").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	/* Move Down */
	$(".move-down-btn").click(function() {
		document.getElementById('about-section').scrollIntoView({ behavior: 'smooth', block: 'center' });
	});

	// Remove button links underline
	$('a > .primary').parent().css('border-bottom-color', 'transparent');
	$('a > .secondary').parent().css('border-bottom-color', 'transparent');
	$('a > .tertiary').parent().css('border-bottom-color', 'transparent');
});

